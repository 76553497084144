import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  CrmFormDataInput2Component,
  CrmFormDataWrapper2Component,
} from 'common-module/form/fragments';
import { CrmTranslatePipe } from 'common-module/translate';
import { DateTime } from 'luxon';

import { FormatDatePipe } from '../../pipes/format-date.pipe';
import { SignalTypeProps } from '../../types/signal-type-props';

import { DateComponent } from './date.component';
import { Date2Component } from './date2.component';

export type FormDateOptions = SignalTypeProps<
  Pick<DateComponent, 'format' | 'disabledDate' | 'allowClear' | 'showTime'>
> & { type: 1 | 2 };

@Component({
  standalone: true,
  selector: 'app-form-date',
  template: `
    <crm-form-data-input-wrapper2
      [config]="config()"
      [status]="status()"
      [error]="error()"
    >
      @if (mode() === 'edit') {
        @switch (options()?.type) {
          @case (2) {
            <app-date2 [formControl]="resolvedControl()"></app-date2>
          }

          @default {
            <app-date
              [formControl]="resolvedControl()"
              [placeholder]="config()?.label | crmTranslate"
              [format]="options()?.format"
              [disabledDate]="options()?.disabledDate"
              [allowClear]="options()?.allowClear"
              [showTime]="options()?.showTime"
            ></app-date>
          }
        }
      } @else {
        {{ data() | formatDate }}
      }
    </crm-form-data-input-wrapper2>
  `,
  imports: [
    ReactiveFormsModule,
    CrmTranslatePipe,
    DateComponent,
    FormatDatePipe,
    CrmFormDataWrapper2Component,
    Date2Component,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDateComponent extends CrmFormDataInput2Component<DateTime> {
  options = input<FormDateOptions>();
}
